import React, { useState, useEffect } from 'react';

const MultiInput = ({ label, subLabel, name, type="text", errors, errorClass, value }) => {
    const [arr, setArray] = useState(
        Array.isArray(value)
            ? value
            : JSON.parse(value) || [""]
    );

    const handleItemChange = (value, idx) => {
        setArray(arr.map((item, i) => i === idx ? value : item));
    }
    const handleAddItem = () => setArray(arr.concat(""));
    const handleRemoveItem = (idx) => setArray(arr.filter((item, i) => i !== idx));

    return<div className="form-group">
        <label className="form-label">
            {label}&nbsp;{ subLabel && <span class="small text-muted">{subLabel}</span> }
        </label>
        {
            arr.map((item, i) => {
                const lastInput = i === arr.length - 1;
                return <div  key={i} className="input-group input-group-merge">
                    <input
                        type="text"
                        className={`form-control form-control-appended ${errorClass}`}
                        name={name}
                        type={type}
                        value={item}
                        onChange={(e) => handleItemChange(e.target.value, i)} />
                    <div className="input-group-append">
                        <div className="input-group-text">
                            {
                                lastInput
                                    ? <a className="fe fe-plus-circle" onClick={handleAddItem}></a>
                                    : <a className="fe fe-x-circle" onClick={() => handleRemoveItem(i)}></a>
                            }
                        </div>
                    </div>
                </div>
            })
        }
        <div className="invalid-feedback" style={{ display: "block"}}>
           { errors && errors.join(", ") }
        </div>
    </div>
}

export default MultiInput;