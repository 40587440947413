/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import React from 'react';
import { render } from 'react-dom';
import SelectRegion from '../components/SelectRegion';
import SelectOrganization from '../components/SelectOrganization';
import MultiInput from '../components/MultiInput';

const renderComponent = () => {
    const $el = document.getElementById('regionField');
    console.log($el);
    if ($el) {
        let name = $el.getAttribute("data-name");
        render(<SelectRegion name={name} />, $el);
    }

    const $orgFieldEl = document.getElementById('reactOrgField');
    if ($orgFieldEl) {
        let props = JSON.parse($orgFieldEl.getAttribute("data-props")) || {};
        render(<SelectOrganization { ...props } />, $orgFieldEl);
    }

    const $multiEls = document.getElementsByClassName('reactMultiInput');
    for (let element of $multiEls) {
        let props = JSON.parse(element.getAttribute("data-props")) || {};
        render(<MultiInput { ...props } />, element);
    }
}

document.addEventListener('DOMContentLoaded', renderComponent);
document.addEventListener('turbolinks:load', renderComponent);

require("trix")
require("@rails/actiontext")