import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Select from 'react-select';

const transformDataToOptions = d => ({ label: d.name, value: d.id });

const NewForm = ({ name, orgName, errors, organization }) => <React.Fragment>
    <div className="row">
        <div className="col-md-8">
            <div className="form-group">
                <label className="form-label">
                    Name <span class="small text-muted">Required</span>
                </label>
                <input
                    className={`form-control ${errors["organization.name"] && "is-invalid"}`}
                    type="text"
                    name={`${name}[organization_attributes][name]`}
                    defaultValue={orgName || organization.name}
                    />
                <div className="invalid-feedback" style={{ display: "block"}}>
                    { errors["organization.name"] && errors["organization.name"].join(", ") }
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="form-group">
                <label className="form-label">Organization Type</label>
                <select className="custom-select" name={`${name}[organization_attributes][org_type]`} defaultValue={organization.org_type}>
                    <option value="public">public</option>
                    <option value="private">private</option>
                </select>
            </div>
        </div>
    </div>
    <div className="form-group">
        <label className="form-label">Email</label>
        <input className="form-control" type="email" name={`${name}[organization_attributes][email]`} defaultValue={organization.email} />
    </div>
    <div className="row">
        <div className="col">
            <div className="form-group">
                <label className="form-label">Website</label>
                <input className="form-control" type="text" name={`${name}[organization_attributes][website]`} defaultValue={organization.website} />
            </div>
        </div>
        <div className="col">
            <div className="form-group">
                <label className="form-label">Contact</label>
                <input className="form-control" type="text" name={`${name}[organization_attributes][contact]`} defaultValue={organization.contact} />
            </div>
        </div>
    </div>
    <div className="form-group">
        <label className="form-label">Address</label>
        <textarea className="form-control" name={`${name}[organization_attributes][id]`} defaultValue={organization.email}></textarea>
    </div>
</React.Fragment>

const SelectField = ({ name, orgId, orgs, setNewOrgName, errors }) => {
    const [selectedOrgId, setSelectedOrgId] = useState(orgId || {});
    const orgChange = v => setSelectedOrgId(v);

    return <React.Fragment>
        <label>Select Organization <span class="small text-muted">Required</span></label>
        <Select
            value={orgs.find((o) => o.value === selectedOrgId)}
            cacheOptions
            options={orgs}
            hideSelectedOptions
            onChange={v => setSelectedOrgId(v.value)}
            noOptionsMessage={
                ({inputValue}) => <div>
                    Organization not found.{' '}
                    <a  href="#"
                        className="text-decoration-underline"
                        onClick={(e) => {
                            e.preventDefault();
                            setNewOrgName(inputValue)
                        }}>Click here to create.</a>
                </div>
            }
            />
        <input  type="hidden"
                name={`${name}[organization_id]`}
                value={selectedOrgId || ""}
                readOnly />
        <div className="invalid-feedback" style={{ display: "block"}}>
            { errors["organization.name"] && errors["organization.name"].join(", ") }
            { errors["organization"] && errors["organization"].join(", ") }
        </div>
    </React.Fragment>
}

const SelectOrganization = ({ name, errors, organization={} }) => {
    const [orgs, setOrgs] = useState([]);
    const [newOrgName, setNewOrgName] = useState(organization.id ? "" : organization.name);

    useEffect(() => {
        const loadOrgs = () => {
            return Axios.get('/public/organizations')
                .then(res => {
                    return res.data;
                })
                .then(data => setOrgs(data.map(transformDataToOptions)));
        };

        loadOrgs();
    }, []);

    return (
        <React.Fragment>
            <div className="form-group">
                {
                    newOrgName
                        ? <NewForm
                            name={name}
                            orgName={newOrgName}
                            errors={errors}
                            organization={organization} />
                        : <SelectField
                            name={name}
                            orgs={orgs}
                            orgId={organization.id}
                            errors={errors}
                            setNewOrgName={setNewOrgName} />
                }
                {
                    newOrgName && <a href="#" onClick={(e)=> {
                        e.preventDefault();
                        setNewOrgName("");
                    }}>Or select from available list.</a>
                }
            </div>
        </React.Fragment>
    );
};

export default SelectOrganization;
