import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Select from 'react-select';

const transformDataToOptions = d => ({ label: d.name, value: d.id });

const SelectRegion = ({ name }) => {
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(null);
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);
    const [municipality, setMunicipality] = useState(null);

    const loadRegions = () => {
        return Axios.get('/public/regions')
            .then(res => {
                return res.data;
            })
            .then(data => setRegions(data.map(transformDataToOptions)));
    };

    const loadProvinces = regionId => {
        return Axios.get(`/public/provinces?region_id=${regionId}`)
            .then(res => {
                return res.data;
            })
            .then(data => setProvinces(data.map(transformDataToOptions)));
    };

    const loadMunicipalities = provinceId => {
        return Axios.get(`/public/areas?province_id=${provinceId}`)
            .then(res => {
                return res.data;
            })
            .then(data => setMunicipalities(data.map(transformDataToOptions)));
    };

    const regionChange = v => {
        setRegion(v);
        setProvinces([]);
        setProvince(null);
        setMunicipalities([]);
        setMunicipality(null);
    };

    const provinceChange = v => {
        setProvince(v);
        setMunicipalities([]);
        setMunicipality(null);
    };

    useEffect(() => {
        loadRegions();
    }, []);

    useEffect(() => {
        if (region && region.value) {
            loadProvinces(region.value);
        }
    }, [region]);
    useEffect(() => {
        if (province && province.value) {
            loadMunicipalities(province.value);
        }
    }, [province]);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label>Region</label>
                        <Select
                            cacheOptions
                            options={regions}
                            hideSelectedOptions
                            onChange={regionChange}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label>Province</label>
                        <Select
                            value={province}
                            cacheOptions
                            isDisabled={!provinces.length}
                            options={provinces}
                            hideSelectedOptions
                            onChange={provinceChange}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label>Municipality</label>
                <Select
                    value={municipality}
                    cacheOptions
                    isDisabled={!municipalities.length}
                    options={municipalities}
                    hideSelectedOptions
                    onChange={v => setMunicipality(v)}
                />
            </div>
            {region && (
                <input
                    type="hidden"
                    name={`${name}[address_region]`}
                    value={region.label}
                />
            )}
            {province && (
                <input
                    type="hidden"
                    name={`${name}[address_province]`}
                    value={province.label}
                />
            )}
            {municipality && (
                <input
                    type="hidden"
                    name={`${name}[address_municipality]`}
                    value={municipality.label}
                />
            )}
        </React.Fragment>
    );
};

export default SelectRegion;
